/* stylelint-disable scss/at-mixin-pattern */

@use 'breakpoints';
@use 'sass:string';

/*
NOTE:
This css nomenclature is retro-compatible with the previous version of safari (15.x)
*/

@mixin onSmall() {
	@media (max-width: #{breakpoints.$medium - 1px}) {
		@content;
	}
}

@mixin onMedium() {
	@media (min-width: #{breakpoints.$medium}) and (max-width: #{breakpoints.$large - 1px}) {
		@content;
	}
}

@mixin onMediumOrGreater() {
	@media (min-width: #{breakpoints.$medium}) {
		@content;
	}
}

@mixin onLarge() {
	@media (min-width: #{breakpoints.$large}) and (max-width: #{breakpoints.$extra-large - 1px}) {
		@content;
	}
}

@mixin onExtraLarge() {
	@media (min-width: #{breakpoints.$extra-large}) {
		@content;
	}
}

@mixin onLessThanLarge() {
	@media (max-width: #{breakpoints.$large - 1px}) {
		@content;
	}
}

@mixin onLargeOrGreater() {
	@media (min-width: #{breakpoints.$large}) {
		@content;
	}
}

:export {
	/* stylelint-disable property-no-unknown */
	issmall: string.unquote('(max-width: #{breakpoints.$medium - 1px})');
	ismedium: string.unquote(
		'(min-width: #{breakpoints.$medium}) and (max-width: #{breakpoints.$large - 1px})'
	);
	islarge: string.unquote(
		'(min-width: #{breakpoints.$large}) and (max-width: #{breakpoints.$extra-large - 1px})'
	);
	isextralarge: string.unquote('(min-width: #{breakpoints.$extra-large})');
	/* stylelint-enable property-no-unknown */
}
