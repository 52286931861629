@use 'breakpoints';

.productTags {
	padding: 4px 0;
	margin: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	text-wrap: nowrap;
	width: 100%;

	&.withIcon {
		width: calc(100% - 24px);
	}
}
