@use 'colors';
@import 'mixins';
@import 'responsive/utils/mediaQueries.module';

.discountRate {
	display: flex;
	align-items: center;
	gap: px2rem(4);
	position: relative;
	padding-block: px2rem(1);
	padding-inline: px2rem(4);
	height: px2rem(18);

	@include onLargeOrGreater {
		height: px2rem(20);
	}

	&.end {
		justify-content: flex-end;
	}

	&.vertical {
		margin: px2rem(4);

		@include onLargeOrGreater {
			margin-inline-end: 0;
		}
	}
}

.discountRateExtraInfo {
	padding-block: px2rem(2);
	padding-inline: px2rem(6);
}

.buttonAccessibility {
	background-color: transparent;
	width: 100%;
	min-width: 34px;
	height: 34px;
	border: none;
	position: absolute;
	right: -10px;
	top: -10px;

	@include onLargeOrGreater {
		min-width: 24px;
		height: 24px;
		right: -5px;
		top: -5px;
	}
}
