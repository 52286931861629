@use 'colors';
@use './breakpoints';

.productDetails {
	display: flex;
	flex-direction: column;
	background-color: colors.$background-default;
	padding: 4px 4px 0 8px;

	@media (min-width: breakpoints.$medium) {
		padding: 8px;
	}
}

.productInfo {
	position: relative;
}

.primaryAction {
	position: absolute;
	top: 0;
	inset-inline-end: 0;
}

.iconContainer {
	flex-shrink: 0;
}

.tagsTitleContainer {
	overflow: hidden;
	white-space: nowrap;
}

// Adjustment with the new typography
// where the line-height is less than icon height
.heading {
	margin-top: 3px;
}
