@use 'texts';
@use 'outline';
@import 'mixins';

$animation: 0.3s ease-out;

%button {
	@extend %text-body-m-regular;

	display: flex;
	align-items: center;
	justify-content: center;
	min-width: px2rem(44);
	gap: px2rem(4);
	cursor: pointer;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	border: 0;
	height: px2rem(44);
	transition:
		color $animation,
		background-color $animation,
		border-color $animation;
	-webkit-tap-highlight-color: transparent;

	&:focus-visible {
		@extend %default-outline-with-offset;
	}

	svg {
		fill: currentcolor;
	}

	&.fullWidth {
		width: 100%;
	}
}

%content-ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
