@use 'colors';
@use 'texts';
@import 'mixins';

.oneSizeContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 14px 6px;
	opacity: 0.9;
	background-color: colors.$background-accent-on-image;
	width: 100%;
}

.sizePicker {
	opacity: 0.9;
	background-color: colors.$background-accent-on-image;
	width: 100%;
}

.sizePickerTitle {
	margin: 0;
	width: 100%;
	text-align: center;
}

.sizePickerContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1.25rem;
	padding: 1rem 1.75rem;
}

.sizePickerContent {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
	width: 100%;
}

.sizePickerAddToBagButton {
	@extend %text-body-l;

	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: none;
	background-color: transparent;
	padding: 0.5rem;
	min-width: 28px;
	height: 1.5rem;

	&:hover {
		background-color: colors.$background-accent-on-inverse-hover;
	}
}

.lastUnits {
	position: relative;

	&::after {
		display: block;
		position: absolute;
		bottom: 0;
		margin: 0 auto;
		border-radius: 2px;
		background: colors.$semantic-warning;
		width: 4px;
		height: 4px;
		content: '';
	}
}
