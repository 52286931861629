@use './breakpoints';

.button {
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;
	width: 34px;
	height: 34px;

	@media (min-width: breakpoints.$large) {
		width: 24px;
		height: 24px;
	}
}
