@use 'colors';
@import 'mixins';

.sizeSheetBody {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0 0 1rem;
	margin: 0 -1rem;
}

.sizeSheetAddToBagButton {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	background-color: transparent;
	border: none;
	padding: px2rem(17);

	.disabled {
		background-color: colors.$background-default;
	}
}

.sizeSheetAddToBagButtonContainer {
	display: flex;
	justify-content: space-between;
	border-width: 1px 0 0;
	border-style: solid;
	border-color: colors.$border-subtle;
	width: 100%;

	&:first-child {
		border-top-width: 0;

		.sizeSheetAddToBagButton {
			padding-top: px2rem(5);
		}
	}

	&:last-child {
		border-bottom-width: 1px;
	}
}

.lastUnitsText {
	color: colors.$semantic-warning;
	width: auto;
}

.backInStockText {
	color: colors.$content-default;
	width: auto;
}
