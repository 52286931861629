@use 'colors';

.overlaylabel {
	position: absolute;
	top: -8px;
	inset-inline-start: 8px;
	transform: translate(0, -100%);
	margin: 0;
	background-color: colors.$background-accent-on-image;
	padding: 4px 8px;
	max-width: calc(100% - 16px);
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
