@use 'colors';
@import 'mixins';

.finalPrice {
	color: colors.$promotions-promotion-sales;
}

.crossed {
	text-decoration: line-through;
	text-decoration-color: inherit;
}

.container {
	display: flex;
	align-items: center;
	gap: px2rem(5);
}

.center {
	justify-content: center;
	margin: 0;
}

.end {
	justify-content: flex-end;
}

.start {
	justify-content: flex-start;
}

.nowrap {
	white-space: nowrap;
}

.allVerticalSpacing {
	margin: 0;
}

.allVertical {
	display: flex;
	flex-direction: column;
	margin: 0;

	&.start {
		align-items: flex-start;
	}

	&.center {
		align-items: center;
	}

	&.end {
		align-items: flex-end;
	}
}
