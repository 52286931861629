@use 'motion';

.productImage {
	width: 100%;
	aspect-ratio: var(--local-aspect-ratio, calc(5 / 7));
}

.slideShow {
	width: 100%;
	aspect-ratio: var(--local-aspect-ratio, calc(5 / 7));
}

.imageWrapper {
	position: relative;
	flex-shrink: 0;
	min-width: 0;
	min-height: 0;
	width: 100%;
	height: fit-content;
	aspect-ratio: var(--local-aspect-ratio, calc(5 / 7));
}

.invisible {
	opacity: 0;
}

.fadeIn {
	opacity: 1;
	animation: fade-in motion.$duration-short2 motion.$easing-calm;

	@media (prefers-reduced-motion: reduce) {
		animation: none;
	}

	@keyframes fade-in {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}
}
