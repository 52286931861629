@use 'colors';
@use 'texts';
@import 'mixins';

.content {
	@extend %text-body-s;

	position: absolute;
	box-sizing: border-box;
	border: 1px solid colors.$border-default;
	background: colors.$background-default;
	padding: px2rem(8) px2rem(12);
	max-width: px2rem(480);
	font-size: px2rem(10);
	width: max-content;
}

.arrow {
	position: absolute;
	box-sizing: border-box;
	border: solid colors.$border-default;
	border-width: 0;
	background: colors.$background-default;
	width: px2rem(8);
	height: px2rem(8);
}

.tooltipHidden {
	visibility: hidden;
}
