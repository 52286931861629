@use 'colors';

$offset: 2px;

// Use this mixin to apply an outline to an element on media queries
@mixin outline {
	outline: 1px solid colors.$content-default;
}

%default-outline {
	outline: 1px solid colors.$content-default;
}

%inverse-outline {
	outline: 1px solid colors.$content-default-on-inverse;
}

%inverse-outline-with-offset {
	@extend %inverse-outline;

	outline-offset: $offset;
}

%default-outline-with-offset {
	@extend %default-outline;

	outline-offset: $offset;
}
