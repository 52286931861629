@use 'breakpoints';

.productTitle {
	margin: 0 0 2px;
	overflow: hidden;
	text-overflow: ellipsis;
	text-wrap: nowrap;
	display: block;
	width: 100%;

	&.withIcon {
		width: calc(100% - 24px);
	}
}
