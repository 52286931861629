// Primary
$primary-0: #fff;
$primary-10: #fafafa;
$primary-20: #f3f3f3;
$primary-30: #d7d7d8;
$primary-40: #b8b8b8;
$primary-50: #424242;
$primary-60: #2b2b2b;
$primary-70: #131313;
$primary-80: #131313;

// Secondary
$secondary-0: #fff;
$secondary-10: #fafafa;
$secondary-20: #f3f3f3;
$secondary-30: #d7d7d8;
$secondary-40: #b8b8b8;
$secondary-50: #6f6f6f;
$secondary-60: #131313;
$secondary-70: #131313;
$secondary-80: #131313;

// Neutral
$neutral-0: #fff;
$neutral-10: #fafafa;
$neutral-20: #f3f3f3;
$neutral-30: #d7d7d8;
$neutral-40: #b8b8b8;
$neutral-50: #a3a3a3;
$neutral-60: #8c8c8c;
$neutral-70: #757575;
$neutral-80: #424242;
$neutral-90: #131313;

// Red
$red-5: #fef1f1;
$red-10: #fad9d9;
$red-20: #f6b3b3;
$red-30: #f18d8d;
$red-40: #ed6767;
$red-50: #dc2e2e;
$red-60: #cb4848;
$red-70: #802424;
$red-80: #5d1a1a;

// Orange
$orange-5: #fdf5f1;
$orange-10: #fee7db;
$orange-20: #fddbc9;
$orange-30: #fbb792;
$orange-40: #fa9f6e;
$orange-50: #f9874a;
$orange-60: #cc6e3b;
$orange-70: #b25d2e;
$orange-80: #733c1e;

// Beige
$beige-5: #fcfaf8;
$beige-10: #f5f1eb;
$beige-20: #ebe2d6;
$beige-30: #e0d4c2;
$beige-40: #d6c5ad;
$beige-50: #ccb799;
$beige-60: #ab997e;
$beige-70: #8b7b64;
$beige-80: #6a5c49;

// Green
$green-5: #f9fcf8;
$green-10: #e6f2e0;
$green-20: #cce5c0;
$green-30: #b3d9a1;
$green-40: #99cc81;
$green-50: #80bf62;
$green-60: #66994e;
$green-70: #4d733b;
$green-80: #334c27;
