@use 'colors';
@use '../styles/base';
@import '../styles/states';
@import 'mixins';

.default,
.inverse {
	@extend %button;

	padding: 0 px2rem(32);
	min-width: px2rem(90);
}

.default {
	color: colors.$content-accent-on-accent;

	@include states(
		'background-color',
		colors.$background-accent,
		colors.$background-accent-hover,
		colors.$background-accent-active
	);
}

.inverse {
	color: colors.$content-accent;

	@include states(
		'background-color',
		colors.$content-accent-on-inverse,
		colors.$content-accent-on-inverse-hover,
		colors.$content-accent-on-inverse-active
	);

	&:focus-visible {
		@extend %inverse-outline-with-offset;
	}
}

.disabled {
	&.default,
	&.inverse {
		color: colors.$content-disabled;

		@include states(
			'background-color',
			colors.$background-disabled,
			colors.$background-disabled,
			colors.$background-disabled
		);
	}
}
