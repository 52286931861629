@use 'colors';
@use 'texts';

.disclaimer {
	background-color: colors.$background-highlight;
	padding-block: 0.25rem;
	padding-inline: 0.25rem;
	margin-block: 0.5rem;
	overflow-wrap: break-word;
	text-wrap: auto;
	border-bottom: 1px solid colors.$border-subtle;

	@extend %text-body-m;
}
