@use 'colors';
@use 'breakpoints';
@use 'texts';
@import 'mixins';

.bodyContent {
	padding: 8px 0;

	@media (min-width: breakpoints.$medium) {
		padding-right: px2rem(8);
		padding-left: px2rem(8);
	}
}

.pricesHistory {
	display: flex;
	gap: px2rem(4);
	margin-top: px2rem(3);

	:global(.pricesHistoryFinalPrice) {
		color: colors.$promotions-promotion-sales;
	}
}

.title {
	@extend %text-body-s-regular;

	margin: 0;
}

.pricesDescription {
	margin: 0;
	margin-top: px2rem(16);
}

.buttonAccesibilityReverse {
	left: 0;
}

.pricesList {
	margin: 0;
	padding: 0;
	list-style-type: '';
}
