@use 'colors';

.overlayButton {
	width: 100%;
	height: 24px;
	color: colors.$content-default;
	background-color: transparent;
	border: none;
	cursor: pointer;

	&:hover {
		background-color: transparent;
	}
}
