@use 'colors';
@use 'outline';
@use '../../styles/base';
@import '../../styles/states';

.disabled,
.inverse,
.default {
	@extend %button;

	background: transparent;
	min-width: initial;
}

.default {
	@include states('color', colors.$border-accent, colors.$border-accent-hover, colors.$border-accent-active);
}

.inverse {
	@include states(
		'color',
		colors.$border-accent-on-inverse,
		colors.$border-accent-on-inverse-hover,
		colors.$border-accent-on-inverse-active
	);

	&:focus-visible {
		@extend %inverse-outline;
	}
}

[aria-disabled='true'] {
	&.default,
	&.inverse {
		@include states('color', colors.$content-disabled, colors.$content-disabled, colors.$content-disabled);

		cursor: not-allowed;
	}
}
