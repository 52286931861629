@use 'colors';

.loader {
	display: flex;
	justify-content: center;
}

.square {
	height: 4px;
	width: 4px;
	margin: 0 4px;
	background-color: colors.$content-default;
	animation: scale-square 1.25s linear infinite;

	&.inverse {
		background-color: colors.$content-default-on-inverse;
	}
}

.square2 {
	animation-delay: 0.25s;
}

.square3 {
	animation-delay: 0.5s;
}

@keyframes scale-square {
	0% {
		transform: scale(1);
	}

	25% {
		transform: scale(1.5);
	}

	50% {
		transform: scale(1);
	}
}
