@import 'mixins';

.container {
	position: relative;
}

.tooltip {
	& > div {
		padding-right: px2rem(0);
		padding-left: px2rem(0);
	}
}

.icon {
	display: flex;
	justify-content: center;
	align-items: center;
}

.iconVertical {
	margin-left: 0.5rem;
}
