@use 'colors';

.discountRateContainer {
	align-items: center;
	background-color: colors.$background-default-on-image;
	display: flex;
	position: relative;

	&.hightlightedDiscount {
		background-color: colors.$background-accent;
		color: colors.$content-default-on-inverse;

		& * {
			fill: colors.$content-default-on-inverse;
			font-weight: 500;
		}
	}
}
