@use 'sass:color';
@use './palette';

// Background
// Los colores de Background, se refieren a los colores de fondo de los diferentes elementos de la interfaz, como pueden ser la página principal, una sección, una tarjeta, etc.
// Estos colores deben asegurar  que no haya problemas de legibilidad o contraste y para que la interfaz tenga una apariencia coherente y atractiva.
// Los tokens de color vendrán definidos por su nombre, estos tokens se llamarán siempre: Background.
$background-default: palette.$neutral-0;
$background-default-on-image: color.adjust(palette.$neutral-0, $alpha: -0.1); // 90%;
$background-surface: palette.$neutral-0;
$background-highlight: palette.$beige-10;
$background-highlight-strong: palette.$beige-30;
$background-overlay: color.adjust(palette.$neutral-80, $alpha: -0.75); // 25%;
$background-subtle: palette.$neutral-10;
$background-inverse: palette.$neutral-90;
$background-accent: palette.$primary-70;
$background-accent-on-inverse: palette.$primary-0;
$background-accent-on-image: color.adjust(palette.$primary-0, $alpha: -0.2); // 80%;

// Background States
// Estos estados corresponden al Background de accent, el único que permite interacción.
$background-accent-hover: palette.$primary-50;
$background-accent-on-inverse-hover: palette.$primary-20;
$background-accent-on-image-hover: palette.$primary-0;
$background-accent-active: palette.$primary-60;
$background-accent-on-inverse-active: palette.$primary-10;
$background-accent-on-image-active: color.adjust(palette.$primary-0, $alpha: -0.2); // 80%;
$background-disabled: palette.$neutral-30;
$background-surface-hover: palette.$neutral-20;
$background-surface-active: palette.$neutral-10;

// Content
// Los tokens de texto son valores predefinidos de estilos de color que se utilizan para aplicar color a
// diferentes elementos de texto o contenido. Al igual que con los tokens de color de fondo, el uso de
// tokens de color aplicados a texto o contenido ayuda a mantener la consistencia visual y la coherencia
// en toda la interfaz de usuario. También pueden ayudar a garantizar que los colores se utilicen de manera
// coherente en todo el producto o marca.
$content-default: palette.$neutral-90;
$content-default-on-inverse: palette.$neutral-0;
$content-strong: palette.$neutral-80;
$content-strong-on-inverse: palette.$neutral-20;
$content-subtle: palette.$neutral-70;
$content-subtle-on-inverse: palette.$neutral-30;
$content-notifications: palette.$red-50;
$content-sustainability: palette.$green-60;
$content-accent-promotions: palette.$red-50;
$content-accent: palette.$primary-70;
$content-accent-on-inverse: palette.$primary-0;
$content-accent-on-accent: palette.$primary-0;
$content-default-on-promotions: palette.$neutral-0;

// Content States
// Estos estados corresponden al Content Accent, el único que permite interacción.
$content-accent-hover: palette.$primary-50;
$content-accent-on-inverse-hover: palette.$primary-20;
$content-accent-active: palette.$primary-60;
$content-accent-on-inverse-active: palette.$primary-10;
$content-accent-unselected: palette.$neutral-70;
$content-disabled: palette.$neutral-50;
$content-disabled-on-inverse: palette.$neutral-30;
$content-accent-promotions-hover: palette.$red-50;
$content-accent-promotions-active: palette.$red-50;
$content-accent-promotions-unselected: palette.$red-60;

// Border
// Estos tokens se utilizan para aplicar color a los bordes de diferentes elementos en la interfaz como en cajas, botones etc- Los tokens de border pueden ser útiles para mejorar la accesibilidad y la usabilidad, ya que permiten a los usuarios distinguir visualmente diferentes elementos y estados.
$border-default: palette.$neutral-90;
$border-default-on-inverse: palette.$neutral-0;
$border-strong: palette.$neutral-40;
$border-subtle: palette.$neutral-30;
$border-accent: palette.$primary-70;
$border-accent-on-inverse: palette.$primary-0;
$border-accent-promotions: palette.$red-50;

// Border States
// Estos son los estados del color de borde de acción.
$border-accent-hover: palette.$primary-50;
$border-accent-on-inverse-hover: palette.$primary-20;
$border-accent-active: palette.$primary-60;
$border-accent-on-inverse-active: palette.$primary-10;
$border-disabled: palette.$neutral-50;
$border-accent-promotions-hover: palette.$red-50;
$border-accent-promotions-active: palette.$red-50;

// Semantic
// Los Semánticos, son colores que se utilizan para transmitir información importante o para representar diferentes estados en una interfaz de usuario_ Por ejemplo, un botón rojo podría indicar una acción de "eliminar", mientras que un botón verde podría indicar una acción de "confirmar"_ En resumen, los colores semánticos ayudan a mejorar la usabilidad y la accesibilidad de una interfaz de usuario al comunicar información de manera rápida y efectiva.
// Su característica principal es que interactúan y dan información al usuario_ Son colores transversales cuya aplicación depende de su finalidad del mensaje que se quiera transmitir y no del lugar, Suelen ser elementos interactivos de primer nivel.
$semantic-error: palette.$red-60;
$semantic-error-soft: palette.$red-5;
$semantic-warning: palette.$orange-70;
$semantic-warning-soft: palette.$orange-5;
$semantic-info: palette.$neutral-90;
$semantic-info-soft: palette.$beige-10;

// Promotions
// Los Promotions, son colores que se utilizan para informar de contenido comercial comercial, como campañas, promociones, códigos descuentos etc
$promotions-promotion-sales: palette.$red-50;
$promotions-promotion-bf: palette.$neutral-90;

// Promotions (deprecar cuando se borren los tokens equivalentes en outlet). Ninguno de estos existen en foundations shop pero si en outlet.
// Se consumen desde ClosableWrapper en fukku y Homes, por lo tanto shop tiene que tener un valor asignado por default para que la build pase correctamente.
$promotions-promotion2: palette.$red-50;
$promotions-promotion3: palette.$red-50;
$promotions-promotion4: palette.$red-50;
$promotions-promotion5: palette.$red-50;
$promotions-promotion6: palette.$red-50;
$promotions-promotion7: palette.$red-50;
$promotions-promotion8: palette.$red-50;
$promotions-promotion9: palette.$red-50;
