.cookiesWrapper {
	position: fixed;
	z-index: 2; // Problem with header sticky, needs same z-index
}

.middle {
	top: 50%;
	bottom: initial;
	left: 50%;
	transform: translate(-50%, -50%);
	width: initial;
}
