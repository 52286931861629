@import 'responsive/utils/mediaQueries.module';

.cookiesFooter {
	display: flex;
	gap: 12px;
	width: 100%;

	@include onLessThanLarge {
		flex-direction: column;
	}

	@include onLargeOrGreater {
		flex-direction: row;
		justify-content: flex-end;
	}
}

.centerCookiesFooter {
	@include onLargeOrGreater {
		justify-content: center;
	}
}

.button {
	@include onLargeOrGreater {
		width: max-content;
	}
}
