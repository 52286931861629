@use 'colors';
@use 'breakpoints';
@use 'motion';

.slideshowWrapper {
	position: relative;
	overflow: clip;
	touch-action: pan-y;

	@media (hover: hover) {
		.arrow {
			opacity: 0;
			transition: opacity 0.3s ease-in-out;
		}

		&:hover {
			.arrow {
				opacity: 1;
			}
		}
	}
}

.animated {
	--animation-direction: -1;

	animation: slide motion.$duration-large3 ease motion.$duration-short2;

	@media (prefers-reduced-motion: reduce) {
		animation: none;
	}
}

[dir='rtl'] .animated {
	--animation-direction: 1;
}

@keyframes slide {
	0% {
		transform: translateX(0);
	}

	35% {
		transform: translateX(calc(75% * var(--animation-direction)));
	}

	65% {
		transform: translateX(calc(75% * var(--animation-direction)));
	}

	100% {
		transform: translateX(0);
	}
}

.preloadedItem {
	width: 0;
	height: 0;
	visibility: hidden;
	display: flex;
}

.slideshow {
	display: flex;
	transform: translateX(var(--slideshow-offset));
	transition-duration: var(--slideshow-transition-duration);
	transition-property: transform;
	width: 100%;
	aspect-ratio: var(--local-aspect-ratio, calc(5 / 7));
}

.bullets {
	display: none;

	@media (pointer: coarse) {
		display: flex;
		position: absolute;
		bottom: 1rem;
		inset-inline: 0;
		margin: auto;
		justify-content: center;
		align-items: center;
		gap: 0.5rem;
	}
}

.bullet {
	display: inline-block;
	background: colors.$content-default-on-inverse;
	width: 4px;
	height: 4px;
	mix-blend-mode: difference;
}

.active {
	width: 16px;
	height: 4px;
}

.arrow {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
	background: transparent;
	border: 0;
	padding: 0;
	width: 44px;
	height: 44px;

	@media (pointer: coarse) {
		display: none;
	}

	&:disabled .prevIcon,
	&:disabled .nextIcon {
		fill: colors.$content-disabled;
		cursor: initial;
	}
}

.prevArrow {
	inset-inline-start: 0;
}

.nextArrow {
	inset-inline-end: 0;
}
