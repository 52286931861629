@use 'motion';
@use '../styles/base';

.loadingText {
	animation: loading-text-animation motion.$duration-short3 motion.$easing-calm forwards;
}

.loader {
	animation: loader-animation motion.$duration-short3 motion.$easing-calm forwards;

	@media (prefers-reduced-motion: reduce) {
		transition: none;
	}
}

.content {
	@extend %content-ellipsis;
}

@keyframes loading-text-animation {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

@keyframes loader-animation {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
