@use 'colors';
@use 'outline';
@use '../styles/base';
@import '../styles/states';
@import 'mixins';

$border: 1px solid;

.disabled,
.inverse,
.default {
	@extend %button;

	background: transparent;
	padding: 0 px2rem(32);
	min-width: px2rem(90);
}

.default {
	@include states(
		'border',
		$border colors.$border-accent,
		$border colors.$border-accent-hover,
		$border colors.$border-accent-active
	);
	@include states(
		'color',
		colors.$content-accent,
		colors.$content-accent-hover,
		colors.$content-accent-active
	);
}

.inverse {
	@include states(
		'border',
		$border colors.$border-accent-on-inverse,
		$border colors.$border-accent-on-inverse-hover,
		$border colors.$border-accent-on-inverse-active
	);
	@include states(
		'color',
		colors.$content-accent-on-inverse,
		colors.$content-accent-on-inverse-hover,
		colors.$content-accent-on-inverse-active
	);

	&:focus-visible {
		@extend %inverse-outline-with-offset;
	}
}

[aria-disabled='true'] {
	&.default,
	&.inverse {
		@include states(
			'border',
			$border colors.$border-disabled,
			$border colors.$border-disabled,
			$border colors.$border-disabled
		);

		color: colors.$content-disabled;
	}
}
