@use 'colors';
@import 'mixins';

.container {
	display: flex;
	flex-direction: column;
}

.container.horizontalContainer {
	flex-direction: row-reverse;
	justify-content: flex-end;
	align-items: flex-start;
	gap: px2rem(4);

	&.additionalPrice {
		align-items: flex-start;
	}
}

.wrapper {
	display: flex;
}

.centerWrapper {
	justify-content: center;
}

.horizontalWrapper {
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	gap: 0 px2rem(5);

	&.reverse {
		flex-direction: row-reverse;
	}
}

.crossedPricesWrapper {
	display: flex;
}

.start {
	align-items: flex-start;
}

.center {
	align-items: center;
}

.end {
	align-items: flex-end;
}

.vertical {
	flex-direction: column;

	&.reverse {
		flex-direction: column-reverse;
	}
}

.horizontal {
	flex-direction: row;
	gap: px2rem(5);

	&.reverse {
		flex-direction: row-reverse;
	}
}

.restCrossedOutPrice {
	color: colors.$content-subtle;
}
