.productImageContainer {
	--local-aspect-ratio: calc(5 / 7);

	aspect-ratio: var(--local-aspect-ratio);
	position: relative;
	overflow: hidden;

	.overlayContainer {
		position: absolute;
		bottom: 0;
		transform: translateY(100%);
		transition: transform 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
		width: 100%;
	}

	&:hover {
		.overlayContainer {
			transform: translateY(0);
		}
	}
}

.featured {
	--local-aspect-ratio: calc(7 / 4.89);

	aspect-ratio: var(--local-aspect-ratio);
}
