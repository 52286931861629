@mixin states($property, $default, $hover, $active) {
	#{$property}: $default;

	&:focus-visible {
		#{$property}: $hover;
	}

	@media (hover: hover) {
		&:hover {
			#{$property}: $hover;
		}
	}

	&:active {
		#{$property}: $active;
	}
}
