@use 'colors';

.colorSheetBody {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(26px, 26px));
	justify-content: center;
	gap: 16px;
	padding: 24px;
}

.colorButton {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: colors.$background-accent-on-inverse;
	padding: 0;
	width: 26px;
	height: 26px;
	border: none;
	border-radius: 50%;

	img {
		border-radius: 50%;
		padding: 1px;

		&.selected {
			border: 1px solid colors.$border-default;
		}
	}
}
