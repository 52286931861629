.productCard {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

.emptyFeatured {
	aspect-ratio: 7/4.89;
	overflow: hidden;
}

.empty {
	aspect-ratio: 5/7;
	overflow: hidden;
}
