@use 'colors';
@use 'texts';
@import 'responsive/utils/mediaQueries.module';

.cookiesNotice {
	background-color: colors.$background-default;
	padding: 0 16px 16px;
	position: relative;
	border: 1px solid colors.$border-subtle;
	color: colors.$content-default;
	width: calc(100vw - 48px);

	@include onLargeOrGreater {
		display: flex;
		gap: px2rem(40);
		padding: 32px 56px 56px;
		width: 100%;
	}
}

.cookiesNoticeContent {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	color: colors.$content-default;
}

.rejectFirstLevel {
	align-self: flex-end;
	margin-top: 16px;

	@include onLargeOrGreater {
		margin-top: 0;
	}
}

.cookiesPolicy {
	margin-top: 8px;
	margin-bottom: 24px;

	@include onLargeOrGreater {
		margin-top: 8px;
		margin-bottom: 32px;
	}
}

.cookiesTitle {
	margin-top: 16px;
	margin-bottom: 8px;
	width: 100%;

	@include onLargeOrGreater {
		margin: 8px 0;
	}

	h2 {
		margin: 0;
		text-align: start;
	}
}

.italic {
	@extend %text-title-l;

	font-style: italic;
}
