@use 'colors';

.colorPicker {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 1px;
	background-color: colors.$background-accent-on-inverse;
	width: 100%;
	margin-top: 8px;
}

.colorButton {
	width: 24px;
	height: 24px;
	border: none;
	cursor: pointer;
	background-color: colors.$background-accent-on-inverse;
	padding: 0;

	span {
		width: 18px;
		height: 18px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	img {
		border-radius: 50%;
	}

	&:hover {
		background-color: colors.$background-accent-on-inverse;

		span {
			border: 1px solid colors.$border-default;
		}
	}

	&.selected {
		span {
			border: 1px solid colors.$border-default;
		}
	}
}
