@use 'colors';
@import 'mixins';

.container {
	position: relative;
}

.icon {
	display: flex;
	justify-content: center;
	align-items: center;
}

.iconVertical {
	margin-inline-start: 0.5rem;
}
